import Image from 'next/image';
import { FC } from 'react';

import s from './HeaderLogo.module.scss';

export const HeaderLogo: FC = () => {
  return (
    <>
      <div className={s.logoFull}>
        <Image
          src="/icons/logo.svg"
          aria-hidden="true"
          width={237}
          height={22}
          alt=""
        />
      </div>

      <div className={s.logoMobile}>
        <Image
          src="/icons/logoSmall.svg"
          aria-hidden="true"
          width={30}
          height={30}
          alt=""
        />
      </div>

      <span className="visually-hidden">Литрес Самиздат</span>
    </>
  );
};
